const defaultHeader: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const getOptions: RequestInit = {
  method: 'GET',
  headers: defaultHeader,
};

const postOptions: RequestInit = {
  method: 'POST',
  headers: defaultHeader,
};

export type BaseResponseType =
  | Record<string, unknown>
  | Record<string, unknown>[];

type JSONResponse<T> =
  | {
      data?: T;
    }
  | T;

class BaseAPI {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected constructor() {}

  protected static _baseURL: string;
  private static get baseURL() {
    return this._baseURL;
  }

  protected static _apiKey: string;
  private static get apiKey() {
    return this._apiKey;
  }

  static async get<T extends BaseResponseType>(path: string): Promise<T> {
    if (this.apiKey) {
      getOptions.headers = {
        ...getOptions.headers, 
        Authorization: `ApiKey ${this.apiKey}`, 
      };
    }
    if (this.baseURL) {
      const apiURL = `${this.baseURL}${path}`;
      const response = await fetch(apiURL, getOptions);
      const responseObj: JSONResponse<T> = await response.json();

      if (response.ok && responseObj) {
        const resolved = (
          responseObj instanceof Array || !responseObj.data
            ? responseObj
            : responseObj.data
        ) as T;
        return Promise.resolve(resolved);
      } else {
        return Promise.reject(response.statusText);
      }
    }
    console.log(new Error(`Undefined _baseURL in API implementation.}`));
    return Promise.reject(
      new Error(`Undefined _baseURL in API implementation.`),
    );
  }

  static async post(
    path: string,
    data: Record<string, unknown>,
  ): Promise<boolean> {
    if (this.baseURL) {
      const apiURL = `${this.baseURL}${path}`;
      const response = await fetch(apiURL, {
        ...postOptions,
        body: JSON.stringify(data),
      });

      if (response.ok) {
        return Promise.resolve(true);
      } else {
        return Promise.reject(response.statusText);
      }
    }
    console.log(new Error(`Undefined _baseURL in API implementation.}`));
    return Promise.reject(
      new Error(`Undefined _baseURL in API implementation.`),
    );
  }
}

export default BaseAPI;
